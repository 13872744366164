import {
  ArrayIcon,
  Badge,
  Button,
  Pane,
  Spinner,
  Text,
  Tooltip,
  majorScale,
} from 'evergreen-ui'
import { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGetTagsByContextTypeQuery } from '../../services/tags/tags.service'
import { CommentContextType, Tag } from '../../types'

interface TagSelectorProps {
  contextType: CommentContextType
  selected: Tag[]
  onSelect: (tags: Tag[]) => void
  showClearButton?: boolean
}

const TagSelector: FC<TagSelectorProps> = (props: TagSelectorProps) => {
  const [activeTags, setActiveTags] = useState<Tag[]>(props.selected)
  // API call to get the video data
  const { data: tags, isFetching } = useGetTagsByContextTypeQuery(
    props.contextType,
    {
      refetchOnMountOrArgChange: 300,
    },
  )
  const [searchParams, setSearchParams] = useSearchParams();
  
  // reset selected tag/s when the component mounts
  useEffect(() => {
    const tagName = searchParams.get('tag')
    if (tagName) {
      const tag = tags?.find((t) => t.name === tagName)
      if (tag) {
        setActiveTags([tag])
      }
    } else {
      setActiveTags([])
    }
  }, [tags, searchParams])

  const toggleActiveTag = (tag: Tag) => {
    //code to just set one value
    setActiveTags([tag])
    //navigate(`?${tag.name}`)
    setSearchParams({tag: tag.name})

    // code to toggle if you wanted to set multiple
    // setActiveTags(
    //   activeTags.includes(id)
    //     ? activeTags.filter((i) => i !== id)
    //     : [...activeTags, id],
    // )
  }

  const clearTags = () => {
    setActiveTags([])
    setSearchParams({})
  }

  useEffect(() => {
    props.onSelect(activeTags)
  }, [activeTags])

  const renderTags = () => {
    let newTags = tags?.map((tag) => {
      return (
        <Button
          key={tag.id}
          appearance="minimal"
          padding={0}
          onClick={() => {
            toggleActiveTag(tag)
          }}
          opacity={activeTags?.flatMap((t) => t.id)?.includes(tag.id) ? 1 : 0.6}
        >
          <Badge
            isInteractive={true}
            key={tag.id}
            color={tag.color}
            width={'100%'}
            height={'100%'}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Tooltip content={tag.description}><Text>{tag.name}</Text></Tooltip>
          </Badge>
        </Button>
      )
    })
    // if we have a collection, add a 'clear filters' button
    if (newTags && props.showClearButton) {
      newTags = [
        <Button
          border="1px solid #d8dae5"
          key="clear"
          appearance="minimal"
          iconBefore={ArrayIcon}
          onClick={() => clearTags()}
        >
          Show All
        </Button>,
        ...newTags,
      ]
    }
    return newTags
  }

  return <Pane display='flex' flexWrap='wrap' gap={majorScale(1)}>{isFetching ? <Spinner /> : renderTags()}</Pane>
}
export default TagSelector
